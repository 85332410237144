import { NOT_NUMBER, MORE_THREE_NUMBERS, LAST_NUMBERS_CPF, EXTRA_NUMBERS_CPF } from '@/constants/regex';

export const maskMoney = (value: any, isInitialValue: boolean = false): string => {
  let newValue = value?.toString() || '';
  if (isInitialValue) {
    newValue = newValue.includes('.') ? Number(newValue.toString()).toFixed(2) : `${newValue}00`;
  }

  const result = newValue.replace(/\D/g, '');
  if (result.length === 1) {
    return result.replace(/^(\d)/, 'R$ 0,0$1');
  }

  if (result.length === 2) {
    return result.replace(/^(\d\d)/, 'R$ 0,$1');
  }

  if (result.length === 3) {
    return result.replace(/^(^0?)(^\d)(\d\d$)/, 'R$ $2,$3');
  }

  if (result.length >= 4 && result.length <= 5) {
    return result.replace(/^(^0?)(\d*)(\d\d$)/, 'R$ $2,$3');
  }

  if (result.length >= 6 && result.length <= 8) {
    return result.replace(/^(\d*)(\d{3})(\d\d$)/, 'R$ $1.$2,$3');
  }
  if (result.length >= 9) {
    return result.replace(/^(\d)(\d{3})(\d{3})(\d{2}).*/, 'R$ $1.$2.$3,$4');
  }

  return result;
};

export const maskPhone = (value: string) =>
  value
    .toString()
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');

const qtdPhoneDigitsWithDdd = 11;
export const maskPhoneNineDigits = (value: string) =>
  value
    .toString()
    .replace(/\D/g, '')
    .slice(0, qtdPhoneDigitsWithDdd)
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d{5})(\d{1,4})$/, '$1-$2');

export const maskCEP = (value: string) => value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');

export const maskDate = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');

export const maskOnlyLetters = (value: string) => value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');

export const maskOnlyNumbers = (value: string) => value.replace(/\D/g, '');
export const maskOnlyNumbersAndDecimals = (value: string) => value.replace(/[^\d,]/g, '').replace(',', '.');
export const maskNumbersWithDots = (value: string) =>
  value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');

function shouldIgnoreWordToUpperCase(wordToIgnore: string): boolean {
  const wordsToIgnore: string[] = ['de', 'da', 'do', 'das', 'dos', 'e'];

  return wordsToIgnore.includes(wordToIgnore.trim());
}

function toUpperCaseIfNotIncludedInIgnoredWords(text: string, positionUppercase: number): string {
  const firstCharacterInUpperOrLowerCase = shouldIgnoreWordToUpperCase(text)
    ? text.charAt(positionUppercase)
    : text.charAt(positionUppercase).toUpperCase();

  if (positionUppercase === 0) {
    return firstCharacterInUpperOrLowerCase + text.slice(positionUppercase + 1);
  }

  return text.charAt(0) + firstCharacterInUpperOrLowerCase + text.slice(positionUppercase + 1);
}

export const upperAllWordsWhenNotShort = (word: string): string => {
  if (!word) {
    return '';
  }

  return word?.toLowerCase().replace(/[\s\\.^-]\w{1,99}|^\w{1,99}/g, (words: string, index: number) => {
    const selectedText = words.toLowerCase();
    const firstLetterPosition: number = 0;

    if (index === firstLetterPosition) {
      return toUpperCaseIfNotIncludedInIgnoredWords(selectedText, firstLetterPosition);
    }

    return toUpperCaseIfNotIncludedInIgnoredWords(selectedText, firstLetterPosition + 1);
  });
};

export const stringToCpfMask = (cpfString: string): string => {
  let newCpfString = cpfString || '';
  newCpfString = newCpfString.toString();
  return newCpfString
    .replace(NOT_NUMBER, '')

    .replace(MORE_THREE_NUMBERS, '$1.$2')
    .replace(MORE_THREE_NUMBERS, '$1.$2')
    .replace(LAST_NUMBERS_CPF, '$1-$2')

    .replace(EXTRA_NUMBERS_CPF, '$1');
};

export const removeMaskFromCpf = (cpf: string) => cpf.replace(/[\\.-]/gi, '');
export const removeMarkers = (cpf: string) => cpf.replace(/[\\.,-]/gi, '');
export const maskNumberToMoney = (value: number) =>
  value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(/\s/, ' ');
